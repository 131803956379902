'use client';

import React, { useEffect } from 'react';
import { Heading } from 'react-aria-components';
import { useAnalytics } from '#/packages/analytics';
import AuthenticationLayout from '#/packages/authentication/components/AuthenticationLayout';
import { Button } from '#/packages/ui';

export const ErrorPage: React.FC<{
  title?: string;
  subtitle?: string;
  fallbackURL?: string;
  buttonText?: string;
  status?: number;
}> = ({ title, subtitle, fallbackURL, buttonText, status }) => {
  const analytics = useAnalytics();

  useEffect(() => {
    if (status === 401) {
      analytics.track('unauthorized.error');
    }
  }, [analytics, status]);

  return (
    <AuthenticationLayout>
      <div className='items-center mx-auto flex flex-col text-center max-w-80'>
        <div className='mt-10'>
          <Heading className='text-2.5xl font-semibold'>{title || `Page not found`}</Heading>
        </div>
        <div className='my-3 max-w-60 mx-auto'>
          <p className='text-xs text-gray-750 leading-normal font-medium'>
            {subtitle || `The page you are looking for has been removed, renamed or unavailable.`}
          </p>
        </div>
        <Button
          size='xl'
          wFull
          justify='center'
          flex
          color={'secondary'}
          href={fallbackURL || '/'}
          className='mt-5 mb-5 max-w-60 mx-auto'
        >
          {buttonText || `Back to Homepage`}
        </Button>
      </div>
    </AuthenticationLayout>
  );
};
